.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.navbar {
  display: flex;
  justify-content: space-between;
  width: 100vw;
  height: 70px;
  background-color: #405cf5;
  padding: 0 30px;
}

button {
  appearance: button;
  backface-visibility: hidden;
  background-color: #405cf5;
  border-radius: 20px;
  border-width: 0;
  box-shadow: rgba(50, 50, 93, 0.1) 0 0 0 1px inset,
    rgba(50, 50, 93, 0.1) 0 2px 5px 0, rgba(0, 0, 0, 0.07) 0 1px 1px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-family: -apple-system, system-ui, "Segoe UI", Roboto, "Helvetica Neue",
    Ubuntu, sans-serif;
  font-size: 100%;
  height: 60px;
  line-height: 1.15;
  margin: 12px 0 0;
  outline: none;
  overflow: hidden;
  padding: 10px 10px 10px 10px;
  position: relative;
  text-align: center;
  text-transform: none;
  transform: translateZ(0);
  transition: all 0.2s, box-shadow 0.08s ease-in;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 400px;
  margin: 0;
}

button:disabled {
  cursor: default;
}

button:focus {
  box-shadow: rgba(50, 50, 93, 0.1) 0 0 0 1px inset,
    rgba(50, 50, 93, 0.2) 0 6px 15px 0, rgba(0, 0, 0, 0.1) 0 2px 2px 0,
    rgba(50, 151, 211, 0.3) 0 0 0 4px;
}

button:hover {
  background-color: #fdfefe;
  color: #405cf5;
}

.sign-out {
  background-color: #fff;
  color: #405cf5;
  width: 300px;
  height: 40px;
}

.landing {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.upload-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 60px;
  padding-bottom: 50px;
}

.nav {
  width: 200px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.input-block {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

label {
  font-size: large;
}

.project {
  background: #f0f0f0;
  width: 1250px; /* Adjust width based on content */
  padding: 5px;
  margin-bottom: 10px;
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start; /* Align items at the top */
  gap: 20px;
  box-shadow: 0 0 5px 1px rgba(50, 50, 93, 0.1), 0 2px 5px rgba(50, 50, 93, 0.1),
    0 1px 1px rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  justify-content: space-around;
}

.project:hover {
  cursor: pointer;
}

.spinner {
  animation: App-logo-spin infinite 2s linear;
  font-size: 100px;
}

li {
  list-style: none;
  display: block;
}

.lab {
  display: flex;
  align-items: center;
}

.files {
  list-style-type: none;
  padding: 0;
  text-align: center;
}

.input {
  background: #f0f0f0;
  width: 1250px; /* Adjust width based on content */
  padding: 5px;
  margin-bottom: 10px;
  box-shadow: 0 0 5px 1px rgba(50, 50, 93, 0.1), 0 2px 5px rgba(50, 50, 93, 0.1),
    0 1px 1px rgba(0, 0, 0, 0.07);
  border-radius: 10px;
}

.toggler:hover {
  cursor: pointer;
}
